:root {
  --post-thumb: url('https://res.cloudinary.com/lekarz/image/upload/c_scale,w_1000/v1634160876/lek/common/stethoscope-6497490_1920_bxrusq.webp');
}

//
// Variables
// ----------------------

$gray: #333;
$gray-light: #aaa;
$gray-lighter: #eee;
$space: 40px;
$blue: #428bca;
$blue-dark: darken($blue, 5%);
$color-gray: #777;

html {
  scroll-behavior: smooth;

}

body {
  margin: 0;
  font-family: helvetica neue, Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.8;
  color: #333;
  background-color: #f8f8f8;

}

@media (min-width: 768px) {
  body {
    line-height: 1.5;
  }
}

a {
  background-color: transparent;
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

strong {
  font-weight: 700;
}


img {
  border: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
}

.bg-light {
  background-color: #f9f9f9;
}

button, input {
  color: inherit;
  font: inherit;
  margin: 0;
}

.form {
  &.search {
    input {
      width: 100%;
    }
  }
}

.search {
  display: block;
}

button.search {
  padding: 11px;
  float: right;
}

.not-set {
  font-size: .7rem;
  color: $color-gray;
}

ul {
  padding-left: 15px;

  &.no-list {
    list-style-type: none;
    padding: 0;
  }
}

ul, ol {
  margin-top: 0;
  margin-bottom: 10px;
}


button {
  cursor: pointer;
}


table {
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  padding: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input, button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #337ab7;
  text-decoration: none;
}

a:hover, a:focus {
  color: #23527c;
  text-decoration: underline;
}

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

img {
  vertical-align: middle;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

[role=button] {
  cursor: pointer;
}

h1, h2, h3, h4 {
  font-family: inherit;
  color: inherit;
  clear: both;
  margin-bottom: 10px;
}

h1, h2, h3 {
  margin-top: 20px;
}


h1 {
  font-size: 20px;
  color: #257271;
}

h2 {
  font-size: 18px;
  color: #006967;
}

h3 {
  font-size: 16px;
  color: #006967;
}

h4 {
  font-size: 14px;
  margin-top: 10px;
}


abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #777;
}

.container {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}


.col-sm-2, .col-sm-3, .col-sm-6, .col-md-4, .col-md-5, .col-md-7, .col-md-8, .col-sm-9, .col-sm-10, .col-xs-12, .col-sm-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-12 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

@media (min-width: 768px) {
  .col-sm-2, .col-sm-3, .col-sm-6, .col-sm-9, .col-sm-10, .col-sm-12 {
    float: left;
  }

  
  .col-sm-12 {
    width: 100%;
  }

  .col-sm-10 {
    width: 83.33333333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.66666667%;
  }
}

@media (min-width: 992px) {
  .col-md-4, .col-md-5, .col-md-7, .col-md-8 {
    float: left;
  }

  .col-md-8 {
    width: 66.66666667%;
  }

  .col-md-7 {
    width: 58.33333333%;
  }

  .col-md-5 {
    width: 41.66666667%;
  }

  .col-md-4 {
    width: 33.33333333%;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;

  td, th {
    padding: 5px;
  }
}

.table > tbody > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  border-top: 1px solid #ddd;
}

.table-bordered {
  border: 1px solid #ddd;
}

.table-bordered > tbody > tr {
  & > td,
  & > th {
    border: 1px solid #ddd;
  }
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%;
  text-align: left;
}

.no-wrap {
  white-space: nowrap;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 2px;
}

.btn:hover, .btn:focus {
  color: #333;
  text-decoration: none;
}

.btn:active {
  outline: 0;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.btn-primary {
  color: #fff;
  font-size: 18px;
  background-color: #006967;
  border: 1px solid #00413f;
  cursor: pointer;

  &:hover, &:focus {
    color: #fff;
    background: #006967;
  }
}


.btn-xs {
  padding: 4px 8px;
  font-size: 12px;
}


.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}

.btn-block {
  display: block;
  width: 100%;
}

.strong {
  font-weight: 700;
}

.hidden {
  display: none;
}

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  vertical-align: baseline;
  border-radius: .25em;
}

.label:empty {
  display: none;
}

.label-success {
  background-color: #5cb85c;
}

.label-warning {
  background-color: #ea8c06;
  color: #fff;

}

.label-danger {
  background-color: #d5304d;
  color: #fff;
}

.label-info {
  background-color: #1a7691;
  color: #fff;
}

.label-muted {
  background-color: $gray-light;
  color: #000;
}


.text-success {
  color: #5cb85c;
}

.text-warning {
  color: #ea8c06;

}

.text-danger {
  color: #d5304d;
}

.text-info {
  color: #1a7691;
}

.text-muted {
  color: $gray-light;
}


.list-group {
  margin-bottom: 20px;
  padding-left: 0;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.list-group-item:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.well {
  min-height: 20px;
  padding: 5px;
  margin-bottom: 10px;
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: 0 1px 3px #abbaad;
}

@media (min-width: 768px) {
  .well {
    padding: 5px 15px;
  }
}

.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .2;
  filter: alpha(opacity=20);
}

.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .5;
  filter: alpha(opacity=50);
}

button.close {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
}

.clearfix:before, .clearfix:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after {
  content: " ";
  display: table;
}

.clearfix:after, .container:after, .container-fluid:after, .row:after {
  clear: both;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

.m0 {
  margin: 0;
}

.mb10 {
  margin-bottom: 10px;
}

.mt30 {
  margin-top: 30px;
}


input[type=search] {
  padding: 10px;
  float: left;
  margin-bottom: 10px;
  width: calc(100% - 100px);
}

.container-inner {
  max-width: 1140px;
  margin: auto;
}

#header {
  padding-bottom: 20px;
  margin-top: 20px;

  h1 {
    font-size: 1.6rem;
  }
}

.care-types {
  padding-left: 20px;

  li {
    font-size: 15px;
    line-height: 22px;
  }
}

.menu-glowne-container {
  background-color: #006967;
  width: 100%;
  text-align: center;
}

.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  border-radius: 3px;

  > li {
    display: inline-block;

    & + li:before {
      position: relative;
      padding: 0 7px 0 5px;
      content: " > ";
      font-size: 12px;
    }

    a {
      font-size: 18px;
      padding: 14px 9px;
      display: inline-block;
    }

  }
}


#specialization-menu, .specialization-menu-sidebar {
  .menu {
    padding: 0;
    list-style-type: none;
    margin: 0 -15px;
  }


  & > a {
    display: block;
    padding: 12px 24px;
    color: #4a4949;
    text-decoration: none;
    text-align: left;
    font-size: 15px;
    border-bottom: 1px solid #f2f2f2;
  }

  & > a:hover {
    color: #303030;
    background: #e3e8e3;
  }

}


.photo {
  padding: 8px 0;
}

#footer {
  background-color: #fff;
  border-top: 1px solid #f2f2f2;
}

.menu {
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin: auto;

    a {
      color: #fff;
      padding: 15px 20px;
      display: block;
    }
  }


}

#catapultCookie {
  background: #3dc0bd;
  color: #f2f2f2;
}

#catapult-cookie-bar {
  box-sizing: border-box;
  max-height: 0;
  opacity: 0;
  z-index: 99999;
  overflow: hidden;
  color: #ddd;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #464646;
  text-align: center;
}

#catapult-cookie-bar a {
  color: #fff;
  margin: 5px;
}

button#catapultCookie {
  background: #fff;
  color: #3d3d3d;
  border: 0;
  padding: 6px 9px;
  border-radius: 3px;
  margin: 0 5px;
}

@media (max-width: 991px) {
  .well {
    text-align: center;
  }
}

.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .2;
  filter: alpha(opacity=20);
}

.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .5;
  filter: alpha(opacity=50);
}

button.close {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
}

.pull-left {
  float: left
}

.text-center {
  text-align: center;
}

.img-thumbnail {
  margin: 10px;
  height: auto;
}

figure {
  margin: 0;
}

.wp-block-image.size-full img {
  margin: 0 1rem 1rem 0;
  height: auto;
  float: left;
  max-width: 100%;
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
  clear: both;
}


.filter-menu {
  width: 100%;
  padding: 10px;
}

.responsive-table {
  overflow-x: auto;
}

.column-left .well {
  border-radius: 3px;
  box-shadow: 0 1px 3px #abbaad;
  border: none;
  padding: 5px 15px;

  ul {
    list-style-type: none;
  }

  a {
    padding: 5px;
    display: block;
    border-bottom: 1px solid #eee;
  }
}


.alert {
  padding: 10px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, .2);
  border-radius: 4px;
  margin: 15px 0;
}

.small {
  font-size: 80%;
  font-weight: 300;
  color: #666;
}

.muted {
  color: #999;
}


//
// Modal
// ----------------------


.modal-close {
  float: right;
  color: $gray-light;
  font-size: 30px;
  text-decoration: none;
  position: absolute;
  right: 5px;
  top: 0;
  background: none;

}

.modal {
  .modal-dialog {
    background: #fefefe;
    border: $gray solid 1px;
    border-radius: 5px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 11;
    width: 100vw;
    height: 100vh;


    transition: transform 0.3s ease-out;
    transform: translate(0, 0);

    overflow-x: auto;
    opacity: 1;
  }


  &.hidden {
    .modal-dialog {
      transform: translate(-100%, 0);
      overflow-x: auto;
      opacity: 0;
    }
  }
}


.modal-body {
  height: calc(100vh - 120px);
  overflow-y: auto;
}

.modal-header,
.modal-footer {
  padding: $space/4 $space/2;
}

.modal-header {
  border-bottom: $gray-lighter solid 1px;
  height: 120px;
  position: sticky;
  top: 0;

  .modal-title {
    margin-right: 5px;
    font-size: 15px;
  }

  box-shadow: 0 0 4px rgba(0, 0, 0, .2);
}

.modal-footer {
  border-top: $gray-lighter solid 1px;
  text-align: right;
}


// cookie
#cookie-info {
  position: fixed;
  bottom: 0;
  background: rgba(255, 255, 255, 1);
  padding: 1rem;
  text-align: center;
  border-top: 1px dotted #888;
  width: 100%;
  opacity: 1;

  &.hidden {
    display: none;
    opacity: 0;
  }
}

// single post
.post-title {
  .well {
    background: #ffffffa3 var(--post-thumb) no-repeat;
    background-size: cover;

    h1 {
      font-size: 20px;
      color: #333;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 5px;
      padding: 1rem;
      margin: 0;
      font-weight: 500;
    }
  }
}

.pagination {
  margin: 1rem 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-auto-flow: column;
  justify-items: stretch;
  text-align: center;

  li {
    border: 1px solid #eee;

    span, a {
      padding-top: 10px;
      padding-bottom: 10px;
      display: block;
    }

    &.active {
      background: #eee;
    }
  }
}

.list-view {
  & > div {
    margin-bottom: 1rem;
  }
}


.has-title[title] {
  padding-right: 25px;

  &:after {
    content: "\2139";
    position: absolute;
    right: 3px;
    border: 1px solid #eee;
    padding: 1px;
    background: #fff;
    color: #000;
    border-radius: 30px;
    display: block;
    width: 14px;
    height: 14px;
  }

  position: relative;
  display: flex;
  justify-content: center;
}

.has-title[title]:focus::after {
  content: attr(title);
  position: absolute;
  left: -5px;
  top: 90%;
  color: #000;
  width: fit-content;
  z-index: 1;
  min-height: 20px;
  height: auto;
  line-height: inherit;
  padding: 5px;
  margin-bottom: 10px;
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: 0 1px 3px #abbaad;
}

